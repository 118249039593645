import React from "react";
import styled from "styled-components";
import Slider, { Settings } from "react-slick";

import GuideCard from "components/LSRevamp/common/GuideCard";
import withWindowDimensions from "components/common/withWindowDimensions";

interface Props {
  isMobileSized?: boolean;
}

const guideItems = [
  {
    img: "/assets/icons/LSRevamp/GuideSectionLandingPage/money_guide.svg",
    title: "Scholarship Guide",
    content:
      "Know about how you can study at your dream university almost for free",
    gradientColors: { from: "from-[#FFFFFF]", to: "to-[#EDECFF]" },
    downloadUrl:
      "https://drive.google.com/file/d/14meAHTJk7u76Pxve84II5Jx9fWfHyhJG/view?ts=625ebc35",
  },
  {
    img: "/assets/icons/LSRevamp/GuideSectionLandingPage/notebook_guide.svg",
    title: "A-Z of Study Abroad Guide",
    content:
      "Plan of action curated by an expert based on your profile and interests",
    gradientColors: { from: "from-[#FFFFFF]", to: "to-[#EDECFF]" },
    downloadUrl:
      "https://drive.google.com/file/d/14meAHTJk7u76Pxve84II5Jx9fWfHyhJG/view?ts=625ebc35",
  },
  {
    img: "/assets/icons/LSRevamp/GuideSectionLandingPage/file_guide.svg",
    title: "VISA Guide",
    content:
      "Know how to crack the Visa interview and also what you need on D-Day",
    gradientColors: { from: "from-[#FFFFFF]", to: "to-[#EDECFF]" },
    downloadUrl:
      "https://drive.google.com/file/d/14meAHTJk7u76Pxve84II5Jx9fWfHyhJG/view?ts=625ebc35",
  },
];

const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 8000,
};

const GuidesSection = ({ isMobileSized }: Props) => {
  return (
    <div className="bg-[#494789]">
      <div className="w-full pb-7 md:max-w-[1200px] md:mx-auto md:pb-[80px]">
        <h2 className="w-5/6 md:w-full text-center font-bold text-white m-auto text-2xl md:text-4xl pt-7 pb-5 md:pt-[64px] md:pb-[48px]">
          Download free study abroad guides curated by our experts
        </h2>
        {isMobileSized ? (
          <StyledSlider {...settings}>
            {guideItems.map((item, index) => {
              return (
                <GuideCard
                  key={index}
                  img={item.img}
                  title={item.title}
                  content={item.content}
                  gradientColors={item.gradientColors}
                  isButtonVisible={true}
                  downloadUrl={item.downloadUrl}
                />
              );
            })}
          </StyledSlider>
        ) : (
          <div className="flex justify-center gap-6">
            {guideItems.map((item, index) => {
              return (
                <GuideCard
                  key={index}
                  img={item.img}
                  title={item.title}
                  content={item.content}
                  gradientColors={item.gradientColors}
                  isButtonVisible={true}
                  downloadUrl={item.downloadUrl}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default withWindowDimensions(GuidesSection);

const StyledSlider = styled(Slider)`
  .slick-dots {
    position: absolute;
    bottom: -25px;
  }
  .slick-dots > li {
    background: #bdbdbd !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 100% !important;
    &.slick-active {
      background: #757575 !important;
    }
    & > button:before {
      font-size: 0 !important;
    }
  }
`;
